<template>
	<ListServices
		:status="status"
		:textCalendar="textCalendar"
		:reverse="reverse"
		:statusIcon="statusIcon"
		v-model="dateCalendar"
	/>
</template>

<script>
import { mapMutations } from "vuex";

const ListServices = () =>
	import(
		/* webpackChunkName: "ListServices" */ "@/components/listServices.vue"
	);

export default {
	name: "Hisotory",
	components: { ListServices },
	data() {
		return {
			status: ["canceled", "noShow", "done", "noProvided"],
			textCalendar: this.$t("global.components.history.txtCalendar"),
			reverse: true,
			statusIcon: true,
			// dateCalendar: new Date().toISOString().substr(0, 10),
		};
	},
	methods: {
		...mapMutations("services", {
			setDateHistory: "setDateHistory",
		}),
	},
	computed: {
		dateCalendar: {
			get() {
				return this.$store.state.services.calendar.history;
			},
			set(val) {
				this.setDateHistory(val);
			},
		},
	},
};
</script>
